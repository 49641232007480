import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { CommonResponse } from "@/api/response";
import { RegisterRequest } from "@/api/push/request";
import { RegisterResponse } from "@/api/push/response";
import * as PushAPI from "@/api/push";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "push/register";

/**
 * プッシュメッセージ登録更新API（/regist-push）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Register extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;
  registerResponse: RegisterResponse = {} as RegisterResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.registerResponse);
  }

  get getMessage() {
    return this.registerResponse.message;
  }

  get getPreviewUrl() {
    if (this.registerResponse.results) {
      return this.registerResponse.results.previewUrl;
    } else {
      console.log("222")
      return "";
    }
  }

  // MutationActions
  @MutationAction
  async register(registerRequest: RegisterRequest) {
    const registerResponse = await PushAPI.register(registerRequest);
    return {
      registerResponse
    };
  }

  @MutationAction
  async clear() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
  
  @MutationAction
  async clearResponse() {
    return {
      registerResponse: {} as RegisterResponse
    };
  }
}

export default getModule(Register);
