import service from "@/api/service";
import {
  SearchRequest,
  DetailRequest,
  DeleteRequest,
  ChangeFixedRequest,
  RegisterRequest
} from "@/api/push/request";
import { SearchResponse, DetailResponse } from "@/api/push/response";
import { CommonResponse } from "@/api/response";

/**
 * プッシュメッセージ一覧画面APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-push", searchRequest);
  return response.data as SearchResponse;
}

/**
 * プッシュメッセージ詳細情報APIをコールします。
 *
 * @param detailRequest 詳細情報のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/detail-push", detailRequest);
  return response.data as DetailResponse;
}

/**
 * プッシュメッセージ削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-push", deleteRequest);
  return response.data as CommonResponse;
}

/**
 * プッシュメッセージ固定表示変更APIをコールします。
 *
 * @param changeFixedRequest
 * @return CommonResponse
 */
export async function changeFixedOne(changeFixedRequest: ChangeFixedRequest) {
  const response = await service.post("/change-push-fixed", changeFixedRequest);
  return response.data as CommonResponse;
}

/**
 * プッシュメッセージ登録更新APIをコールします。
 *
 * @param registerRequest 登録更新のリクエストボディ
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-push", registerRequest);
  return response.data as CommonResponse;
}
