import service from "@/api/service";
import { GetResponse } from "@/api/push-user-count/response";
import { GetRequest } from "@/api/push-user-count/request";

/**
 * プッシュメッセージ現在配信数取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-push-user-count", getRequest);
  return response.data as GetResponse;
}
