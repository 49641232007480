import service from "@/api/service";
import { GetResponse } from "@/api/schedule-list/response";
import { GetRequest } from "@/api/schedule-list/request";

/**
 * イベント・お知らせ情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-schedule-list", getRequest);
  return response.data as GetResponse;
}
