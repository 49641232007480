import { Component, Mixins, Watch } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import NewEditContent from "@/views/push-notifications/NewEditContent.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import MixinNewEdit from "@/models/push-notifications/mixin-new-edit";
import { DetailResult } from "@/api/push/response";
import PushRegister from "@/store/push/register";
import UserSearch, { ListItem as SearchItem } from "@/store/user-push/search";
import UserList, { ListItem } from "@/store/user-push/list";
import Flash from "@/store/common/flash";
import _ from "lodash";
import WindowOpen from "@/utils/window-open";

@Component({
  components: {
    UIFormRow,
    UIDataTable,
    UIDatePicker,
    NewEditContent
  }
})
export default class NewEditTarget extends Mixins(
  RedirectWithAlert,
  MixinNewEdit
) {
  // 配信除外ユーザー選択ダイアログの表示有無
  showDialog = false;

  // 配信除外されるユーザー
  selectingUsers = [] as ListItem[];

  // 配信除外されるユーザーの一時変数
  selectingUsersTemp = [] as ListItem[];

  /**
   * 配信除外されるユーザーの人数
   */
  get selectedUserCount() {
    return this.selectingUsers.length;
  }

  /**
   * pushDetail props のウォッチャー
   *
   * @param newDetailResult
   * @param oldDetailResult
   */
  @Watch("pushDetail")
  watchDetailResult(
    newDetailResult: DetailResult,
    oldDetailResult: DetailResult
  ) {
    this.syncBaseParams(newDetailResult);
    this.syncSendContentParams(newDetailResult);
    this.syncInputParams(newDetailResult);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    await this.search();
    if (this.isEdit && this.pushDetail) {
      if (this.pushDetail.userIds) {
        await this.searchList(
          this.pushDetail.userIds.map(value => String(value))
        );
      }
      this.syncBaseParams(this.pushDetail);
      this.syncSendContentParams(this.pushDetail);
      this.syncInputParams(this.pushDetail);
    }
  }

  async beforeDestroy() {
    await PushRegister.clear();
    await UserSearch.clearResponse();
    await UserList.clearResponse();
  }

  /**
   * 配信除外ユーザーの解除が押下された際のコールバック
   */
  unselectUserClickCallback(item: ListItem) {
    if (this.selectingUsers) {
      this.selectingUsers = this.selectingUsers.filter(
        u => u.userId !== item.userId
      );
      this.inputParams.userIds = this.selectingUsers.map(u => u.userId);
    }
  }

  /**
   * 配信除外設定ボタンが押下された際のコールバック
   */
  async openDialogClickCallback() {
    this.showDialog = true;
    this.selectingUsersTemp = _.cloneDeep(this.selectingUsers);
    if (!UserSearch.isSuccess) {
      await this.search();
    }
  }

  /**
   * 配信除外ユーザー選択ダイアログの保存ボタンが押下された際のコールバック
   */
  saveDialogClickCallback() {
    this.showDialog = false;
    this.selectingUsers = _.cloneDeep(this.selectingUsersTemp);
    this.inputParams.userIds = this.selectingUsers.map(u => u.userId);
  }

  /**
   * 配信除外ユーザー選択ダイアログのチェックボックスが押下された際のコールバック
   *
   * @param item
   */
  userSelectCallback(item: ListItem) {
    const filtered = this.selectingUsersTemp.filter(
      u => u.userId !== item.userId
    );
    if (this.selectingUsersTemp.length == filtered.length) {
      this.selectingUsersTemp.push(item);
    } else {
      this.selectingUsersTemp = filtered;
    }
  }

  async preview() {
    this.inputParams.isPreview = 1;
    const isSuccess = await this.callRegister();
    if (isSuccess) {
      WindowOpen.preview(PushRegister.getPreviewUrl);
    }
    this.inputParams.isPreview = 0;
  }

  private async callRegister(): Promise<boolean> {
    await PushRegister.register(this.createRegisterRequest());
    if (!PushRegister.isSuccess) {
      await Flash.setErrorNow({
        message: PushRegister.getMessage,
        showReloadButton: false
      });
      window.scrollTo(0, 0);
    }
    return PushRegister.isSuccess;
  }

  /**
   * 登録処理
   */
  async register() {
    // クーポン配信の場合は日付整合性チェック
    if (this.activeSubTab == 1) {     
      var errMsg =  await this.checkCouponDate();
      if (errMsg) {
        await Flash.setErrorNow({
          message: errMsg,
          showReloadButton: false
        });
        window.scrollTo(0, 0);
        return;
      }
    }
    
    await PushRegister.register(this.createRegisterRequest());
    if (PushRegister.isSuccess) {
      await Flash.clear();
      await this.redirectWithSuccessAlert(
        "プッシュメッセージを保存しました。",
        `/push-notifications/${this.shopId}/list?shopName=${this.shopName}`
      );
    } else {
      await Flash.setErrorNow({
        message: PushRegister.getMessage,
        showReloadButton: false
      });
      window.scrollTo(0, 0);
    }
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files) {
      return;
    }
    const file = target.files[0];
    if (file.type !== "text/csv") {
      return;
    }
    const render = new FileReader();
    render.onload = async () => {
      const loadedCSV = render.result as string;
      const lines = loadedCSV.split("\n");
      // 1行目はタイトル行なのでスキップする
      if (lines && lines.length > 1) {
        lines.shift();
        await this.searchList(lines.map(value => String(value)));
        this.selectingUsers = UserList.getItems;
        this.inputParams.userIds = this.selectingUsers.map(u => u.userId);
      }
    };
    render.readAsBinaryString(file);
  }

  /**
   * 配信除外ユーザー選択ダイアログのチェックボックスのON/OFF状態の取得
   *
   * @param item
   */
  getCheckBoxStatus(item: ListItem) {
    return !!this.selectingUsersTemp.find(u => u.userId === item.userId);
  }

  private syncInputParams(pushDetail: DetailResult) {
    // 配信除外ユーザーの同期
    this.inputParams.userIds = pushDetail.userIds;
    this.selectingUsers = UserList.getItems;
  }

  private createRegisterRequest() {
    const request = this.createRegisterCommonRequest();
    request.pushType = 3;
    switch (this.activeSubTab) {
      case 0:
        request.contentsType = 1;
        request.contentsId = this.eventId;
        break;
      case 1:
        request.contentsType = 2;
        request.contentsId = this.couponId;
        break;
      case 2:
        request.contentsType = 5;
        request.title = this.inputParams.title;
        request.comment = this.inputParams.comment;
        break;
    }
    if (this.inputParams.isPreview) {
      request.isPreview = this.inputParams.isPreview;
    }else{
      request.isPreview = 0;
    }
    request.userIds = this.inputParams.userIds;
    return request;
  }
}
