import { Component, Mixins, Watch } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import NewEditContent from "@/views/push-notifications/NewEditContent.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import MixinNewEdit from "@/models/push-notifications/mixin-new-edit";
import { DetailResult } from "@/api/push/response";
import PushRegister from "@/store/push/register";
import UserSearch, { ListItem as SearchItem } from "@/store/user-push/search";
import UserList, { ListItem } from "@/store/user-push/list";
import Flash from "@/store/common/flash";
import _ from "lodash";
import WindowOpen from "@/utils/window-open";
import UIFormSocial from '@/components/UIFormSocial.vue';
import service from "@/api/service";

@Component({
  components: { UIFormRow, UIDataTable, UIDatePicker, NewEditContent, UIFormSocial }
})
export default class NewEditAll extends Mixins(
  RedirectWithAlert,
  MixinNewEdit
) {
  // 配信除外ユーザー選択ダイアログの表示有無
  showDialog = false;

  // 配信除外されるユーザーの一時変数
  leaveUsersTemp = [] as ListItem[];

  // 配信除外されるユーザー
  leaveUsers = [] as ListItem[];

  // 除外なしの配信対象者数
  targetUserCount = 0;
  defaultMessage = '';
  isLoading = false;
  /**
   * 配信されるユーザーの人数
   */

  get sendUserCount() {
    return this.targetUserCount - this.leaveUsers.length;
  }

  /**
   * pushDetail props のウォッチャー
   *
   * @param newDetailResult
   * @param oldDetailResult
   */
  @Watch("pushDetail")
  watchDetailResult(
    newDetailResult: DetailResult,
    oldDetailResult: DetailResult
  ) {
    this.syncBaseParams(newDetailResult);
    this.syncSendContentParams(newDetailResult);
    this.syncInputParams(newDetailResult);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    await this.search();
    if (UserSearch.isSuccess) {
      this.targetUserCount = UserSearch.getTotalCount;
    }

    if (this.isEdit && this.pushDetail) {
      if (this.pushDetail.leaveUsers) {
        await this.searchList(this.pushDetail.leaveUsers);
      }
      this.syncBaseParams(this.pushDetail);
      this.syncSendContentParams(this.pushDetail);
      this.syncInputParams(this.pushDetail);
    }
    var resExt = await service.post("/get-extension-social-shop", {shop_id : this.shopId});
    if (resExt.data) {
      this.inputParams.snsPostLinkFlg = resExt.data.snsPostLinkFlg;
      this.inputParams.snsInfoLinkFlg = resExt.data.snsInfoLinkFlg;
      this.inputParams.twitterPostFlgExt = resExt.data.twitterPostFlg;
      this.inputParams.facebookPostFlgExt = resExt.data.facebookPostFlg;
      this.inputParams.instagramPostFlgExt = resExt.data.instagramPostFlg;
      this.inputParams.googleBizProfPostFlgExt = resExt.data.googleBizProfPostFlg;
      this.defaultMessage = resExt.data.defaultMessage;
      this.inputParams.twitterFlgExt = resExt.data.twitterFlg;
      this.inputParams.facebookFlgExt = resExt.data.facebookFlg;
      this.inputParams.instagramFlgExt = resExt.data.instagramFlg;
      this.inputParams.googleBizProfFlgExt = resExt.data.googleBizProfFlg;
    }

    if(this.inputParams.facebookPostFlgExt != 1 || this.inputParams.facebookFlgExt != 1) {
      this.inputParams.facebookFlag = 0;
    }
    if(this.inputParams.twitterPostFlgExt != 1 || this.inputParams.twitterFlgExt != 1) {
      this.inputParams.twitterFlag = 0;
    }
    if(this.inputParams.instagramPostFlgExt != 1 || this.inputParams.instagramFlgExt != 1) {
      this.inputParams.instagramFlag = 0;
    }
    if(this.inputParams.googleBizProfPostFlgExt != 1 || this.inputParams.googleBizProfFlgExt != 1) {
      this.inputParams.googleBizFlag = 0;
    }
  }

  async beforeDestroy() {
    await PushRegister.clear();
    await UserSearch.clearResponse();
    await UserList.clearResponse();
  }

  /**
   * 配信除外ユーザーの解除が押下された際のコールバック
   */
  unselectUserClickCallback(item: ListItem) {
    if (this.leaveUsers) {
      this.leaveUsers = this.leaveUsers.filter(u => u.userId !== item.userId);
      this.inputParams.leaveUsers = this.leaveUsers.map(u => String(u.userId));
    }
  }

  /**
   * 配信除外設定ボタンが押下された際のコールバック
   */
  async openDialogClickCallback() {
    this.showDialog = true;
    this.leaveUsersTemp = _.cloneDeep(this.leaveUsers);
    if (!UserSearch.isSuccess) {
      await this.search();
    }
  }

  /**
   * 配信除外ユーザー選択ダイアログの保存ボタンが押下された際のコールバック
   */
  saveDialogClickCallback() {
    this.showDialog = false;
    this.leaveUsers = _.cloneDeep(this.leaveUsersTemp);
    this.inputParams.leaveUsers = this.leaveUsers.map(u => String(u.userId));
  }

  /**
   * 配信除外ユーザー選択ダイアログのチェックボックスが押下された際のコールバック
   *
   * @param item
   */
  userSelectCallback(item: SearchItem) {
    const filtered = this.leaveUsersTemp.filter(u => u.userId !== item.userId);
    if (this.leaveUsersTemp.length === filtered.length) {
      this.leaveUsersTemp.push(new ListItem(item.rawItem));
    } else {
      this.leaveUsersTemp = filtered;
    }
  }

  async preview() {
    this.inputParams.isPreview = 1;
    const isSuccess = await this.callRegister();
    if (isSuccess) {
      WindowOpen.preview(PushRegister.getPreviewUrl);
    }
    this.inputParams.isPreview = 0;
  }

  private async callRegister(): Promise<boolean> {
    await PushRegister.register(this.createRegisterRequest());
    if (!PushRegister.isSuccess) {
      await Flash.setErrorNow({
        message: PushRegister.getMessage,
        showReloadButton: false
      });
      window.scrollTo(0, 0);
    }
    return PushRegister.isSuccess;
  }

  /**
   * 登録処理
   */
  async register() {
    this.isLoading = true;
    // クーポン配信の場合は日付整合性チェック
    if (this.activeSubTab == 1) {
      var errMsg =  await this.checkCouponDate();
      if (errMsg) {
        await Flash.setErrorNow({
          message: errMsg,
          showReloadButton: false
        });
        window.scrollTo(0, 0);
        this.isLoading=false;
        return;
      }
    }

    await PushRegister.register(this.createRegisterRequest());
    if (PushRegister.isSuccess) {
      await Flash.clear();
      await this.redirectWithSuccessAlert(
        "プッシュメッセージを保存しました。",
        `/push-notifications/${this.shopId}/list?shopName=${this.shopName}`
      );
    } else {
      var res = PushRegister.registerResponse;
      if(res.errors) {
        console.log(JSON.stringify(res.errors));
        await Flash.clear();
        await Flash.setErrorNow({
          message: PushRegister.getMessage,
          showReloadButton: false,
          messageSuccessBasic: res.messageSuccessBasic ? res.messageSuccessBasic : ''
        });
      } else {
        await Flash.setErrorNow({
          message: PushRegister.getMessage,
          showReloadButton: false
        });
      }
      window.scrollTo(0, 0);
    }
    this.isLoading = false;
  }

  /**
   * 配信除外ユーザー選択ダイアログのチェックボックスのON/OFF状態の取得
   *
   * @param item
   */
  getCheckBoxStatus(item: SearchItem) {
    return !!this.leaveUsersTemp.find(u => u.userId === item.userId);
  }

  private syncInputParams(pushDetail: DetailResult) {
    // 配信除外ユーザーの同期
    this.inputParams.leaveUsers = pushDetail.leaveUsers;
    this.leaveUsers = UserList.getItems;
  }

  private createRegisterRequest() {
    const request = this.createRegisterCommonRequest();
    request.pushType = 1;
    switch (this.activeSubTab) {
      case 0:
        request.contentsType = 1;
        request.contentsId = this.eventId;
        break;
      case 1:
        request.contentsType = 2;
        request.contentsId = this.couponId;
        request.title = this.inputParams.title;
        request.comment = this.inputParams.comment;
        request.twitterPostFlgExt = this.inputParams.twitterPostFlgExt;
        request.facebookPostFlgExt = this.inputParams.facebookPostFlgExt;
        request.instagramPostFlgExt = this.inputParams.instagramPostFlgExt;
        request.googleBizProfPostFlgExt = this.inputParams.googleBizProfPostFlgExt;
        request.twitterFlag = this.inputParams.twitterFlag;
        request.facebookFlag = this.inputParams.facebookFlag;
        request.instagramFlag = this.inputParams.instagramFlag;
        request.googleBizFlag = this.inputParams.googleBizFlag;
        request.twitterMessage = this.inputParams.twitterMessage;
        request.facebookMessage = this.inputParams.facebookMessage;
        request.instagramMessage = this.inputParams.instagramMessage;
        request.googleBizMessage = this.inputParams.googleBizMessage;
        request.snsPostLinkFlg = this.inputParams.snsPostLinkFlg;
        break;
      case 2:
        request.contentsType = 5;
        request.title = this.inputParams.title;
        request.comment = this.inputParams.comment;
        request.twitterPostFlgExt = this.inputParams.twitterPostFlgExt;
        request.facebookPostFlgExt = this.inputParams.facebookPostFlgExt;
        request.instagramPostFlgExt = this.inputParams.instagramPostFlgExt;
        request.googleBizProfPostFlgExt = this.inputParams.googleBizProfPostFlgExt;
        request.twitterFlag = this.inputParams.twitterFlag;
        request.facebookFlag = this.inputParams.facebookFlag;
        request.instagramFlag = this.inputParams.instagramFlag;
        request.googleBizFlag = this.inputParams.googleBizFlag;
        request.twitterMessage = this.inputParams.twitterMessage;
        request.facebookMessage = this.inputParams.facebookMessage;
        request.instagramMessage = this.inputParams.instagramMessage;
        request.googleBizMessage = this.inputParams.googleBizMessage;
        request.snsPostLinkFlg = this.inputParams.snsPostLinkFlg;
        break;
    }
    if (this.inputParams.leaveUsers) {
      request.leaveUsers = this.inputParams.leaveUsers;
    }
    if (this.inputParams.isPreview) {
      request.isPreview = this.inputParams.isPreview;
    }else{
      request.isPreview = 0;
    }
    request.leaveUsers = this.inputParams.leaveUsers;
    return request;
  }
}
