import { Component, Mixins, Watch } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import MixinNewEdit from "@/models/push-notifications/mixin-new-edit";
import { DetailQuestion, DetailResult } from "@/api/push/response";
import { GetRequest } from "@/api/shiten-list/request";
import { RegisterParams } from "@/api/push/request";
import ShitenListGet from "@/store/shiten-list/get";
import PushRegister from "@/store/push/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import WindowOpen from "@/utils/window-open";
import { BusinessServiceStorage } from "@/store/business-service-storage";

/**
 * 店舗別パラメータ
 */
interface ShopQuestion {
  shopIds: number[];
  name: string;
  values: { [key: number]: string };
}

@Component({
  components: { UIFormRow, UIDataTable, UIDatePicker, quillEditor }
})
export default class NewEditQuestionnaire extends Mixins(
  RedirectWithAlert,
  MixinNewEdit
) {
  generalQuestion = null as DetailQuestion[] | null;
  shopQuestion = null as ShopQuestion[] | null;
  businessId = 0 as number;

  showGeneralQuestion = false;
  showShopQuestion = false;

  get shopItems() {
    return ShitenListGet.getItems;
  }

  /**
   * pushDetail props のウォッチャー
   *
   * @param newDetailResult
   * @param oldDetailResult
   */
  @Watch("pushDetail")
  watchDetailResult(
    newDetailResult: DetailResult,
    oldDetailResult: DetailResult
  ) {
    this.syncBaseParams(newDetailResult);
    this.syncInputParams(newDetailResult);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    const request = { id: Number(this.shopId) } as GetRequest;
    await ShitenListGet.get(request);
    if (!ShitenListGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShitenListGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    if (this.isEdit && this.pushDetail) {
      this.syncBaseParams(this.pushDetail);
      this.syncInputParams(this.pushDetail);
    }
    if (this.generalQuestion == null || this.generalQuestion.length == 0) {
      this.generalQuestion = [
        {
          id: null,
          type: 1,
          name: null,
          value: null,
          shopId: null
        } as DetailQuestion
      ];
    }
    if (this.shopQuestion == null || this.shopQuestion.length == 0) {
      this.shopQuestion = [
        { shopIds: [], name: "", values: {} } as ShopQuestion
      ];
    }
  }

  async beforeDestroy() {
    await ShitenListGet.clearResponse();
    await PushRegister.clear();
  }

  async updated() {
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
  }

  /**
   * 固定パラメータの追加ボタンを表示するか否か
   *
   * @param index
   */
  isShowAddGeneralParamButton(index: number) {
    if (this.generalQuestion) {
      return this.generalQuestion.length === index + 1;
    } else {
      return false;
    }
  }

  /**
   * 支店パラメータの追加ボタンを表示するか否か
   *
   * @param index
   */
  isShowAddShopParamButton(index: number) {
    if (this.shopQuestion) {
      return this.shopQuestion.length === index + 1;
    } else {
      return false;
    }
  }

  /**
   * 固定パラメータの追加ボタンが押された際のコールバック
   */
  addGeneralParamClickCallback() {
    if (this.generalQuestion) {
      this.generalQuestion.push({
        id: null,
        type: 1,
        name: null,
        value: null,
        shopId: null
      } as DetailQuestion);
    } else {
      this.generalQuestion = [
        {
          id: null,
          type: 1,
          name: null,
          value: null,
          shopId: null
        } as DetailQuestion
      ];
    }
  }

  /**
   * 固定パラメータの追加ボタンが押された際のコールバック
   */
  addShopParamClickCallback() {
    if (this.shopQuestion) {
      this.shopQuestion.push({
        shopIds: [],
        name: "",
        values: {}
      } as ShopQuestion);
    } else {
      this.shopQuestion = [
        { shopIds: [], name: "", values: {} } as ShopQuestion
      ];
    }
  }

  /**
   * 固定パラメータの削除ボタンが押された際のコールバック
   *
   * @param index
   */
  removeGeneralParamClickCallback(index: number) {
    if (this.generalQuestion) {
      this.generalQuestion.splice(index, 1);
      if (this.generalQuestion.length <= 0) {
        // 配列が空になった場合は未入力のオブジェクトをpushする
        this.addGeneralParamClickCallback();
      }
    }
  }

  /**
   * 固定パラメータの削除ボタンが押された際のコールバック
   *
   * @param index
   */
  removeShopParamClickCallback(index: number) {
    if (this.shopQuestion) {
      this.shopQuestion.splice(index, 1);
      if (this.shopQuestion.length <= 0) {
        // 配列が空になった場合は未入力のオブジェクトをpushする
        this.addShopParamClickCallback();
      }
    }
  }

  /**
   * DatePickerの日付が変更された際のコールバック
   */
  changeFromExpireDateCallback(date: string) {
    this.inputParams.pointTermStart = date;
  }

  /**
   * DatePickerの日付が変更された際のコールバック
   */
  changeToExpireDateCallback(date: string) {
    this.inputParams.pointTermEnd = date;
  }

  /**
   * 店舗別パラメータの店舗選択が変更された際のコールバック
   *
   * @param index
   */
  changeShopSelectCallback(index: number) {
    if (this.shopQuestion) {
      const v = {} as { [key: number]: string };
      for (let shopId of this.shopQuestion[index].shopIds) {
        v[shopId] = this.shopQuestion[index].values[shopId];
      }
      this.shopQuestion[index].values = v;
    }
  }

  /**
   * 店舗名を返却する
   *
   * @param id
   */
  getShopName(id: number) {
    const shop = this.shopItems.find(s => s.id === id);
    return shop ? shop.name : "";
  }

  async preview() {
    this.inputParams.isPreview = 1;
    const isSuccess = await this.callRegister();
    if (isSuccess) {
      WindowOpen.preview(PushRegister.getPreviewUrl);
    }
    this.inputParams.isPreview = 0;
  }

  private async callRegister(): Promise<boolean> {
    await PushRegister.register(this.createRegisterRequest());
    if (!PushRegister.isSuccess) {
      await Flash.setErrorNow({
        message: PushRegister.getMessage,
        showReloadButton: false
      });
      window.scrollTo(0, 0);
    }
    return PushRegister.isSuccess;
  }

  /**
   * 登録処理
   */
  async register() {
    await PushRegister.register(this.createRegisterRequest());
    if (PushRegister.isSuccess) {
      await Flash.clear();
      await this.redirectWithSuccessAlert(
        "プッシュメッセージを保存しました。",
        `/push-notifications/${this.shopId}/list?shopName=${this.shopName}`
      );
    } else {
      await Flash.setErrorNow({
        message: PushRegister.getMessage,
        showReloadButton: false
      });
    }
  }

  private syncInputParams(pushDetail: DetailResult) {
    this.inputParams.pointTermFlg = pushDetail.pointTermFlg;
    this.inputParams.pointTermStart = pushDetail.pointTermStart;
    this.inputParams.pointTermEnd = pushDetail.pointTermEnd;
    this.inputParams.linkUrl = pushDetail.linkUrl;
    this.inputParams.title = pushDetail.title;
    this.inputParams.comment = pushDetail.comment;
    // アンケートリンク設定の同期
    if (pushDetail.question) {
      // 固定アンケートの同期
      this.generalQuestion = pushDetail.question.filter(q => q.type === 1);
      if (this.generalQuestion && this.generalQuestion.length > 0) {
        this.showGeneralQuestion = true;
      }
      // 支店別アンケートの同期
      const shopQuestion = pushDetail.question.filter(q => q.type === 2);
      this.shopQuestion = [];
      for (let question of shopQuestion) {
        const shopId = question.shopId;
        if (!shopId) {
          continue;
        }
        let exists = false;
        for (let i = 0; i < this.shopQuestion.length; i++) {
          if (this.shopQuestion[i].name === question.name) {
            // 同じパラメータ名が存在する場合
            this.shopQuestion[i].shopIds.push(shopId);
            if (question.value) {
              this.shopQuestion[i].values[shopId] = question.value;
            }
            exists = true;
            break;
          }
        }
        if (!exists) {
          // 同じパラメータ名が存在しない場合
          const newQuestion = {
            shopIds: [question.shopId],
            name: question.name,
            values: {}
          } as ShopQuestion;
          if (question.value) {
            newQuestion.values[shopId] = question.value;
          }
          this.shopQuestion.push(newQuestion);
        }
      }
      if (shopQuestion.length > 0) {
        this.showShopQuestion = true;
      }
    } else {
      this.addGeneralParamClickCallback();
      this.addShopParamClickCallback();
    }
  }

  private createRegisterRequest() {
    const request = this.createRegisterCommonRequest();
    request.pushType = 5;
    request.pointTermFlg = this.inputParams.pointTermFlg;
    request.title = this.inputParams.title;
    request.comment = this.inputParams.comment;
    if (this.inputParams.pointTermFlg === 1) {
      request.pointTermStart = this.inputParams.pointTermStart;
      request.pointTermEnd = this.inputParams.pointTermEnd;
    }
    if (this.inputParams.linkUrl) {
      request.linkUrl = this.inputParams.linkUrl;
    }
    let question = [] as RegisterParams[];
    if (this.showGeneralQuestion && this.generalQuestion) {
      question = this.generalQuestion.map(q => {
        return {
          type: q.type,
          shopId: q.shopId,
          name: q.name,
          value: q.value
        } as RegisterParams;
      });
    }
    if (this.showShopQuestion && this.shopQuestion) {
      for (let q of this.shopQuestion) {
        for (let i = 0; i < q.shopIds.length; i++) {
          const shopId = q.shopIds[i];
          question.push({
            type: 2,
            shopId: shopId,
            name: q.name,
            value: q.values[shopId]
          } as RegisterParams);
        }
      }
    }
    if (question.length > 0) {
      request.params = question;
    } else {
      delete request.params;
    }
    if (this.inputParams.isPreview) {
      request.isPreview = this.inputParams.isPreview;
    }else{
      request.isPreview = 0;
    }
    return request;
  }
}
