import { Component, Prop, Mixins } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import NewEditAll from "@/views/push-notifications/NewEditAll.vue";
import NewEditCondition from "@/views/push-notifications/NewEditCondition.vue";
import NewEditTarget from "@/views/push-notifications/NewEditTarget.vue";
import NewEditQuestionnaire from "@/views/push-notifications/NewEditQuestionnaire.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { DetailRequest } from "@/api/push/request";
import PushDetail from "@/store/push/detail";
import PushSerch from "@/store/push/search";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { DetailResult } from "@/api/push/response";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    NewEditAll,
    NewEditCondition,
    NewEditTarget,
    NewEditQuestionnaire
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null; // プッシュメッセージID

  // タイトル
  headingMain = "プッシュメッセージ";
  headingSub = "Push notifications";

  // パンくず
  get breadCrumbs() {
    if (this.isEdit) {
      return [
        { text: "アピール", disabled: true },
        { text: "プッシュメッセージ", disabled: true },
        {
          text: "店舗一覧",
          disabled: false,
          to: { name: "push-notifications" }
        },
        {
          text: "プッシュメッセージ一覧",
          disabled: false,
          to: {
            name: "push-notifications-list",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "編集", disabled: true }
      ];
    } else {
      return [
        {
          text: "店舗一覧",
          disabled: false,
          to: { name: "push-notifications" }
        },
        {
          text: "プッシュメッセージ一覧",
          disabled: false,
          to: {
            name: "push-notifications-list",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        { text: "新規作成", disabled: true }
      ];
    }
  }

  get tabItems() {
    if (this.$route.query.pushLinkFlg === "1") {
      return [
        {
          title: "全員配信",
          component: "NewEditAll",
          disabled: false
        },
        {
          title: "条件配信",
          component: "NewEditCondition",
          disabled: false
        },
        {
          title: "特定配信",
          component: "NewEditTarget",
          disabled: false
        },
        {
          title: "アンケートリンク配信",
          component: "NewEditQuestionnaire",
          disabled: false
        }
      ];
    } else {
      return [
        {
          title: "全員配信",
          component: "NewEditAll",
          disabled: false
        },
        {
          title: "条件配信",
          component: "NewEditCondition",
          disabled: false
        },
        {
          title: "特定配信",
          component: "NewEditTarget",
          disabled: false
        }
      ];
    }
  }

  /**
   * クエリパラメータから店舗IDを返却する（必須）
   */
  get shopId() {
    if (this.$route.query.shopId) {
      return this.$route.query.shopId;
    } else {
      return "";
    }
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get pushDetail() {
    if (this.isEdit) {
      return PushDetail.getDetail;
    } else {
      return {} as DetailResult;
    }
  }

  get isEdit() {
    return !!this.id;
  }

  get activeTab() {
    return this.$route.query.tab ? Number(this.$route.query.tab) : 0;
  }

  set activeTab(val) {
    let query = { ...this.$route.query };
    query.tab = String(val);
    this.$router.replace({ query: query }).then();
  }

  async created() {
    if (!this.shopId) {
      await this.$router.push({ name: "push-notifications" });
      await Flash.setErrorNow({
        message: "パラメータが不足しています。",
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    if (this.isEdit) {
      const request = {
        id: Number(this.id),
        shopId: Number(this.shopId)
      } as DetailRequest;
      await PushDetail.detail(request);
      if (PushDetail.isSuccess) {
        switch (this.pushDetail.pushType) {
          case 1:
            this.activeTab = 0;
            break;
          case 2:
            this.activeTab = 1;
            break;
          case 3:
            this.activeTab = 2;
            break;
          case 5:
            this.activeTab = 3;
            break;
        }
      } else {
        await Flash.setErrorNow({
          message: PushDetail.getMessage,
          showReloadButton: true
        } as ErrorAlert);
      }
    }
  }

  async beforeDestroy() {
    await PushDetail.clearResponse();
  }
}
